import { useEffect, useState, useCallback } from 'react';
import { getUsers, resetUserPassword, deleteUser } from '~common/api';

const dashboardUrl = import.meta.env.VITE_DASHBOARD_DOMAIN;

export const defaultAttributes = [
  'id',
  'firstName',
  'lastName',
  'emailAddress',
  'phoneNumberPrefix',
  'phoneNumber',
  'userType',
];

export default ({ attributes = defaultAttributes } = {}) => {
  const [state, setState] = useState({
    data: [],
    loading: null,
    alert: null,
    selectionModel: [],
    selectedUser: null,
    showAssignLearnersDailog: false,
    showPasswordResetDialog: false,
    showDeleteUserDialog: false,
  });

  const handleGetAllUsers = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    const result = await getUsers({ attributes });
    setState((prevState) => ({
      ...prevState,
      loading: false,
      data: result?.ok?.results ?? [],
      alert: result.isError
        ? {
          severity: 'error',
          message: 'There was an error fetching users please try again later',
        }
        : null,
    }));
  };

  const onSelectionModelChange = useCallback((selectionModel) => {
    const userId = selectionModel[0];
    const selectedUser = state.data
      ? state.data.find((user) => user.id === userId && user.userType !== 'admin')
      : null;

    setState((prevState) => ({
      ...prevState,
      selectionModel,
      selectedUser,
      alert: null,
    }));
  }, [state.data]);

  const onAssumeUserClick = useCallback(() => {
    if (state.selectedUser) {
      window.open(`${dashboardUrl}/?assume=${state.selectedUser.id}`, '_blank').focus();
    }
  }, [state.selectedUser]);

  const onRefreshClick = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
      alert: null,
    }));
    handleGetAllUsers();
  });

  const openAssignLearnersDialog = useCallback(() => {
    if (state.selectedUser && state.selectedUser?.userType === 'tutor') {
      setState((prevState) => ({
        ...prevState,
        showAssignLearnersDailog: true,
      }));
    }
  }, [state.selectedUser]);

  const dismissAssignLearnersDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showAssignLearnersDailog: false,
    }));
  });

  const submitAssignLearnersDialog = useCallback((ok) => {
    setState((prevState) => ({
      ...prevState,
      showAssignLearnersDailog: false,
      alert: ok
        ? {
          severity: 'success',
          message: `Successfully updated learners for ${state.selectedUser.firstName} ${state.selectedUser.lastName}`,
        }
        : {
          severity: 'error',
          message: `There was an error updating learners for ${state.selectedUser.firstName} ${state.selectedUser.lastName}, please try again later`,
        },
    }));
  });

  const openResetPasswordDialog = useCallback(() => {
    if (state.selectedUser && state.selectedUser?.userType !== 'admin') {
      setState((prevState) => ({
        ...prevState,
        showPasswordResetDialog: true,
      }));
    }
  }, [state.selectedUser]);

  const dismissPasswordResetDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showPasswordResetDialog: false,
    }));
  });

  const submitPasswordResetDialog = useCallback(async () => {
    const [result] = await resetUserPassword({ id: state.selectedUser.id });
    setState((prevState) => ({
      ...prevState,
      showPasswordResetDialog: false,
      alert: result
        ? {
          severity: 'success',
          message: `Created new temporary password for user: ${result.password} Please send this to them to allow them to login.`,
        }
        : {
          severity: 'error',
          message: `There was an error updating learners for ${state.selectedUser.firstName} ${state.selectedUser.lastName}, please try again later`,
        },
    }));
  });

  const openDeleteUserDialog = useCallback(() => {
    if (state.selectedUser && state.selectedUser?.userType !== 'admin') {
      setState((prevState) => ({
        ...prevState,
        showDeleteUserDialog: true,
      }));
    }
  }, [state.selectedUser]);

  const dismissDeleteUserDialog = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showDeleteUserDialog: false,
    }));
  });

  const submitDeleteUserDialog = useCallback(async () => {
    const result = await deleteUser({ id: state.selectedUser.id });
    if (result && !result.isError) {
      setState((prevState) => ({
        ...prevState,
        showDeleteUserDialog: false,
        alert: {
          severity: 'success',
          message: `Successfully deleted user: ${state.selectedUser.firstName} ${state.selectedUser.lastName}`,
        },
        data: prevState.data.filter((user) => user.id !== state.selectedUser.id),
        selectedUser: null,
        selectionModel: [],
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        showDeleteUserDialog: false,
        alert: {
          severity: 'error',
          message: `There was an error deleting ${state.selectedUser.firstName} ${state.selectedUser.lastName}, please try again later`,
        },
      }));
    }
  }, [state.selectedUser]);

  useEffect(() => {
    handleGetAllUsers();
  }, []);

  return {
    ...state,
    onSelectionModelChange,
    onAssumeUserClick,
    onRefreshClick,
    openAssignLearnersDialog,
    dismissAssignLearnersDialog,
    submitAssignLearnersDialog,
    openResetPasswordDialog,
    dismissPasswordResetDialog,
    submitPasswordResetDialog,
    openDeleteUserDialog,
    dismissDeleteUserDialog,
    submitDeleteUserDialog,
  };
};
