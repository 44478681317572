import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const DeleteUserDialog = ({ open, onClose, onSubmit }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
      <DialogContentText>
        are you sure you want to delete? Please make sure that this user does not have any upcoming sessions or sessions during the last 3 days!!!
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onSubmit} color="error">
        Delete
      </Button>
    </DialogActions>
  </Dialog>
);

export default DeleteUserDialog;
