import { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import AssignLearnerIcon from '@mui/icons-material/School';
import AssumeUserIcon from '@mui/icons-material/SwitchAccount';
import LockResetIcon from '@mui/icons-material/LockReset';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteUserIcon from '@mui/icons-material/PersonRemove';
import PageTitle from '../../components/pageTitle';
import DataGrid from '../../components/dataGrid';
import DataGridToolbarContainer from '../../components/dataGrid/toolbar/toolbarContainer';
import DataGridToolbarQuickFilter from '../../components/dataGrid/toolbar/toolbarQuickFilter';
import DataGridToolbarSpacer from '../../components/dataGrid/toolbar/toolbarSpacer';
import DataGridToolbarRefreshButton from '../../components/dataGrid/toolbar/toolbarButtons/toolbarRefreshButton';
import DataGridToolbarButton from '../../components/dataGrid/toolbar/toolbarButton';

import AssignLearnersDialog from './assignLearnersDialog';
import DeleteUserDialog from './deleteUserDialog';
import useUsers from './hooks/useUsers';
import useStyles from './styles';
import columns from './columns';
import ResetPasswordDialog from './resetPasswordDialog';

const getToolbar = ({
  selectedUser,
  onAssumeUserClick,
  onRefreshClick,
  openAssignLearnersDialog,
  openResetPasswordDialog,
  openDeleteUserDialog,
  exportToCsv,
}) => function () {
  return (
    <DataGridToolbarContainer>
      <DataGridToolbarRefreshButton onClick={onRefreshClick} />
      <DataGridToolbarButton
        startIcon={<DownloadIcon />}
        onClick={exportToCsv}
        title="Export to CSV"
      >
        Export CSV
      </DataGridToolbarButton>
      <DataGridToolbarQuickFilter />
      <DataGridToolbarSpacer />
      <DataGridToolbarButton
        startIcon={<AssignLearnerIcon />}
        disabled={!(selectedUser && selectedUser.userType === 'tutor')}
        onClick={openAssignLearnersDialog}
      >
        Manage learners
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<DeleteUserIcon />}
        disabled={!(selectedUser && selectedUser.userType !== 'admin')}
        onClick={openDeleteUserDialog}
      >
        Delete user
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<AssumeUserIcon />}
        disabled={!(selectedUser && selectedUser.userType !== 'admin')}
        onClick={onAssumeUserClick}
      >
        Assume user
      </DataGridToolbarButton>
      <DataGridToolbarButton
        startIcon={<LockResetIcon />}
        disabled={!(selectedUser && selectedUser.userType !== 'admin')}
        onClick={openResetPasswordDialog}
      >
        Reset Password
      </DataGridToolbarButton>
    </DataGridToolbarContainer>
  );
};

function Users() {
  const { classes } = useStyles();
  const {
    data,
    loading,
    alert,
    selectedUser,
    selectionModel,
    onSelectionModelChange,
    onAssumeUserClick,
    onRefreshClick,
    showAssignLearnersDailog,
    openAssignLearnersDialog,
    dismissAssignLearnersDialog,
    submitAssignLearnersDialog,
    showPasswordResetDialog,
    openResetPasswordDialog,
    dismissPasswordResetDialog,
    submitPasswordResetDialog,
    showDeleteUserDialog,
    openDeleteUserDialog,
    dismissDeleteUserDialog,
    submitDeleteUserDialog,
  } = useUsers();

  const exportToCsv = useCallback(() => {
    if (!data || data.length === 0) {
      alert('No data available to export.');
      return;
    }

    const headers = ['First Name', 'Last Name', 'User Type', 'Email Address', 'Phone Number'];

    const rows = data.map((user) => [
      user.firstName,
      user.lastName,
      user.userType,
      user.emailAddress,
      user.phoneNumber
        ? `(${user.phoneNumberPrefix?.id || ''}) ${user.phoneNumberPrefix?.value || ''} ${user.phoneNumber}`
        : '',
    ]);

    const csvContent = [headers, ...rows]
      .map((row) => row
        .map((item) => {
          const escapedItem = String(item).replace(/"/g, '""');
          if (/[",\n]/.test(escapedItem)) {
            return `"${escapedItem}"`;
          }
          return escapedItem;
        })
        .join(','))
      .join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    link.setAttribute('download', `users_export_${formattedDate}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }, [data]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <PageTitle title="Users" />
      </div>
      <div className={classes.content}>
        <Grid container className={classes.grid}>
          <Grid item xs={12} className={classes.gridItem}>
            <Paper className={classes.background}>
              {alert && (
                <Alert severity={alert.severity} className={classes.alert}>
                  {alert.message}
                </Alert>
              )}
              <DataGrid
                className={classes.dataGrid}
                density="compact"
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'firstName', sort: 'asc' }],
                  },
                }}
                loading={loading}
                rows={data}
                columns={columns}
                getRowId={(row) => row.id}
                components={{
                  Toolbar: getToolbar({
                    selectedUser,
                    onAssumeUserClick,
                    onRefreshClick,
                    openAssignLearnersDialog,
                    openResetPasswordDialog,
                    openDeleteUserDialog,
                    exportToCsv,
                  }),
                }}
                onSelectionModelChange={onSelectionModelChange}
                selectionModel={selectionModel}
                pageSize={20}
              />
            </Paper>
          </Grid>
        </Grid>
      </div>
      {showAssignLearnersDailog && (
        <AssignLearnersDialog
          id={selectedUser?.id}
          open={showAssignLearnersDailog}
          onClose={dismissAssignLearnersDialog}
          onCancel={dismissAssignLearnersDialog}
          onSubmit={submitAssignLearnersDialog}
        />
      )}
      {showPasswordResetDialog && (
        <ResetPasswordDialog
          id={selectedUser?.id}
          open={showPasswordResetDialog}
          onClose={dismissPasswordResetDialog}
          onCancel={dismissPasswordResetDialog}
          onSubmit={submitPasswordResetDialog}
        />
      )}
      {showDeleteUserDialog && (
        <DeleteUserDialog
          open={showDeleteUserDialog}
          onClose={dismissDeleteUserDialog}
          onSubmit={submitDeleteUserDialog}
        />
      )}
    </div>
  );
}

export default Users;
